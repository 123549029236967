import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Pagination from "../General/Pagination";
import Monthly_navbar from "./Monthlytasks_navbar";
import { scrollToTop } from "../Shared/ScrollOnTop";

const MonthDetails = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);


  const [FYear, setFYear] = useState(urlParams.get('Year'));
  const [SelectedMonth, setSelectedMonth] = useState(urlParams.get('Month'));

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);


  useEffect(() => {

    

  }, [])

  useEffect(() => {
    document.title = "Month Details";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      SchoolID: 1,
      SelectedMonth: SelectedMonth,
      FYear: FYear,
    };

   //console.log(data)
    var api_config = {
      method: "post",
      url: config.base_url + "MonthlyTask/GetMonthDetails",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
       //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>{item.Fee_Code}</td>
            <td>{item.Description}</td>
            <td>{item.Debit}</td>
            <td>{item.Credit}</td>
            <td>{item.NoofStudents}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="6">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Monthly_navbar />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/MonthlyTasks/MonthlyTasksDashboard">
                      Monthly Task
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Month Details
                  </li>
                </ol>
              </nav>
              <div className="page-title">Month Details</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/MonthlyTasks/StartNewMonth"
                  className="button button-white button-icon"
                >
                  Start New Month
                  <i className="ri-add-line"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <div>
                <table className="table table-theme table-hover">
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Fee Code</th>
                      <th>Description</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>No of Students</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colSpan="6">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>{renderLedger()}</>
                    )}
                  </tbody>
                </table>

                {totalRecords > 3 && (
                  <div className="pagination-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-2 form_default">
                        <div className="d-flex align-items-center">
                          <label className="me-2" style={{ fontSize: "15px" }}>
                            Page Size
                          </label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              style={{ width: "100px" }}
                              onChange={(e) => {
                                setPageNo(1);
                                setPageSize(parseInt(e.target.value));
                              }}
                            >
                              <option value="10" selected>
                                10
                              </option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="500">500</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content mt-2">
        <div className="container">
          <div className="card">
            <div className="card-body text-end">
              <Link target="_blank" to={`/MonthlyTasks/MonthDetails/PrintMonthDetails?Month=${SelectedMonth}&Year=${FYear}`} className="button button-primary">Print Month Details</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MonthDetails;
