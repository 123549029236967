import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";


import Dashboard from './components/Dashboard';
import Main from './components/Main';
import StudentDashboard from './components/Students/StudentDashboard';
import AddStudent from './components/Students/AddStudent';
import EditStudent from './components/Students/EditStudent';
import AnnualTasksDashboard from './components/AnnualTasks/AnnualTasksDashboard';
import AddSessions from './components/AnnualTasks/AddSessions';
import Login from './components/Login/Login';
import ManageSessions from './components/AnnualTasks/ManageSessions';
import EditSession from './components/AnnualTasks/EditSession';
import SecurityDashboard from './components/Security/SecurityDashboard';
import AddNewUser from './components/Security/AddNewUser';
import GroupManagement from './components/Security/GroupManagement';
import ManageUsers from './components/Security/ManageUsers';
import EditUser from './components/Security/EditUser';
import AddGroup from './components/Security/AddGroup';
import EditGroup from './components/Security/EditGroup';
import RightsAssignment from './components/Security/RightsAssignment';
import ChangePassword from './components/Security/ChangePassword';
import SetupDashboard from './components/Setup/SetupDashboard';
import NoAccess from './components/General/NoAccess';

import ManageBankAccounts from './components/Setup/ManageBankAccounts';
import ManageConcession from './components/Setup/ManageConcession';
import ManageBusRoutes from './components/Setup/ManageBusRoutes';
import ManageSection from './components/Setup/ManageSection';
import ManageWings from './components/Setup/ManageWings';
import ManageClasses from './components/Setup/ManageClasses';
import ManageFeeTypes from './components/Setup/ManageFeeTypes';
import SchoolInfo from './components/Setup/SchoolInfo';
import AddWings from './components/Setup/AddWings';
import AddClass from './components/Setup/AddClass';
import AddFeeType from './components/Setup/AddFeeType';
import AddBankAcc from './components/Setup/AddBankAcc';
import AddSection from './components/Setup/AddSection';
import AddBusRoutes from './components/Setup/AddBusRoutes';
import EditFeeType from './components/Setup/EditFeeType';
import EditConcession from './components/Setup/EditConcession';
import AddConcession from './components/Setup/AddConcession';
import PrintAttendenceSheet from './components/Setup/PrintAttendenceSheet';
import EditBankAcc from './components/Setup/EditBankAcc';
import PrintTestPerforma from './components/Setup/PrintTestPerforma';
import PrintStudentCards from './components/Setup/PrintStudentCards';



import PayrollDashboard from './components/PayRoll/PayrollDashboard';
import ManageEmployee from './components/PayRoll/ManageEmployee';
import EditBusRoutes from './components/Setup/EditBusRoutes';
import EditWings from './components/Setup/EditWings';
import Absenteelist from './components/Setup/Absenteelist';
import ManageDesignations from './components/PayRoll/ManageDesignations';
import LibraryDashboard from './components/Library/LibraryDashboard';
import AddBook from './components/Library/AddBook';
import ViewLibrary from './components/Library/ViewLibrary';
import SearchStudents from './components/Students/SearchStudents';
import EditClass from './components/Setup/EditClass';
import PrintBioDataForms from './components/Setup/PrintBioDataForms';
import FeeSystemDashboard from './components/FeeSystem/FeeSystemDashboard';
import StruckoffList from './components/Reports/StruckoffList';
import ReportDashboard from './components/Reports/ReportDashboard';
import AccountHeadwiseReport from './components/Reports/AccountHeadwiseReport';

import ConcessionReport from './components/Reports/ConcessionReport';
import DefaultersList from './components/Reports/DefaultersList';
import FeeCollectionDetail from './components/Reports/FeeCollectionDetail';
import FeeReporting from './components/Reports/FeeReporting';
import NewAdmittedUnPaid from './components/Reports/NewAdmittedUnPaid';
import StatusWiseChange from './components/Reports/StatusWiseChange';
import StruckedStudents from './components/Reports/StruckedStudents';
import SectionWiseList from './components/Reports/SectionWiseList';


import SectionWiseClassList from './components/PrintReports/SectionWiseClassList';
import Classwiseattendancesheet from './components/PrintReports/Classwiseattendancesheet';
import AbsentListClassWise from './components/PrintReports/AbsentListClassWise';
import PrintStudentCardsList from './components/PrintReports/PrintStudentCardsList';
import PrintTestPerformaList from './components/PrintReports/PrintTestPerformaList';
import PrintBioDataFormsList from './components/PrintReports/PrintBioDataFormsList';
import PrintOralAssessmentReport1 from './components/PrintReports/PrintOralAssessmentReport1';
import PrintOralAssessmentReport1Urdu from './components/PrintReports/PrintOralAssessmentReport1Urdu';
import PrintOralAssessmentReport2Urdu from './components/PrintReports/PrintOralAssessmentReport2Urdu';










import StudentFineReport from './components/Reports/StudentFineReport';
import StudentStrengthReport from './components/Reports/StudentStrengthReport';
import UnpaidStudent from './components/Reports/UnpaidStudent';
import MonthlyTasksDashboard from './components/MonthlyTasks/MonthlyTasksDashboard';
import ManageMonth from './components/MonthlyTasks/ManageMonth';
import StartNewMonth from './components/MonthlyTasks/StartNewMonth';
import AccountsDashboard from './components/Accounts/AccountsDashboard';
import ManageAccount from './components/Accounts/ManageAccount';
import CertificateDashboard from './components/Certificate/CertificateDashboard';
import PrintCertificates from './components/Certificate/PrintCertificates';
import ExaminationDashboard from './components/ExaminationSystem/ExaminationDashboard';
import AddExaminations from './components/ExaminationSystem/AddExaminations';
import AddSubject from './components/ExaminationSystem/AddSubject';
import AddSubjectType from './components/ExaminationSystem/AddSubjectType';
import CloseExam from './components/ExaminationSystem/CloseExam';
import DataEntry from './components/ExaminationSystem/DataEntry';
import ExamDataEntryOptions from './components/ExaminationSystem/ExamDataEntryOptions';
import ExamDataSheetOptions from './components/ExaminationSystem/ExamDataSheetOptions';
import ManageClassSubjects from './components/ExaminationSystem/ManageClassSubjects';
import ManageExamClasses from './components/ExaminationSystem/ManageExamClasses';
import ManageExaminations from './components/ExaminationSystem/ManageExaminations';
import ManageSubjects from './components/ExaminationSystem/ManageSubjects';
import ManageSubjectTypes from './components/ExaminationSystem/ManageSubjectTypes';
import OldResults from './components/ExaminationSystem/OldResults';
import AdmitStudent from './components/Students/AdmitStudent';
import StocksDashboard from './components/StockManagement/StocksDashboard';
import AddStockCategory from './components/StockManagement/AddStockCategory';
import IssueItems from './components/StockManagement/IssueItems';
import ManageStockCategory from './components/StockManagement/ManageStockCategory';
import StockDetail from './components/StockManagement/StockDetail';
import EditSubjectType from './components/ExaminationSystem/EditSubjectType';
import NewStudentList from './components/Students/NewStudentList';
import ChangeRegistrationNo from './components/Students/ChangeRegistrationNo';
import PrintVouchers from './components/FeeSystem/PrintVouchers';
import StaffAttendanceDashboard from './components/StaffAttendance/StaffAttendanceDashboard';
import MarkAttendance from './components/StaffAttendance/MarkAttendance';
import AttendanceSummary from './components/StaffAttendance/AttendanceSummary';
import LeaveRegister from './components/StaffAttendance/LeaveRegister';
import AttendanceRights from './components/StaffAttendance/AttendanceRights';
import EditSubject from './components/ExaminationSystem/EditSubject';
import EditExam from './components/ExaminationSystem/EditExam';
import ManageEmployeeTypes from './components/PayRoll/ManageEmployeeTypes';
import AddEmployeeType from './components/PayRoll/AddEmployeeType';
import ChangeYear from './components/Accounts/ChangeYear';
import AddNewAccount from './components/Accounts/AddNewAccount';
import ManageVouchers from './components/Accounts/ManageVouchers';
import RPTAwardList from './components/PrintReports/RPTAwardList';
import RPTAwardListClass from './components/PrintReports/RPTAwardListClass';
import OpeningBalances from './components/Accounts/OpeningBalances';
import AddVoucher from './components/Accounts/AddVoucher';
import TrialBalanceLevelWise from './components/Accounts/TrialBalanceLevelWise';
import TrialBalanceHeadWise from './components/Accounts/TrialBalanceHeadWise';
import BudgetHeadWise from './components/Accounts/BudgetHeadWise';
import AddSalary from './components/PayRoll/AddSalary';
import BankSalalryList from './components/PayRoll/BankSalalryList';
import CreateSalary from './components/PayRoll/CreateSalary';
import ManageSalaryTypes from './components/PayRoll/ManageSalaryTypes';
import IncreaseDecreaseSalary from './components/PayRoll/IncreaseDecreaseSalary';
import UpdateSection from './components/Setup/UpdateSection';
import EditOpeningBalance from './components/Accounts/EditOpeningBalance';
import AddSalaryType from './components/PayRoll/AddSalaryType';
import EditSalaryType from './components/PayRoll/EditSalaryType';
import EditEmployeeType from './components/PayRoll/EditEmployeeType';
import ManageLoanDeduction from './components/PayRoll/ManageLoanDeduction';
import AddLoanDeduction from './components/PayRoll/AddLoanDeduction';
import EditLoanDeduction from './components/PayRoll/EditLoanDeduction';
import AddOtherDeduction from './components/PayRoll/AddOtherDeduction';
import ManageOtherLoanDeductions from './components/PayRoll/ManageOtherLoanDeductions';
import EditOtherLoanDeduction from './components/PayRoll/EditOtherLoanDeduction';
import EmployeeLedger from './components/PayRoll/EmployeeLedger';
import AddBankScroll from './components/FeeSystem/AddBankScroll';
import AdvanceSearch from './components/Students/AdvanceSearch';
import AddNewEmployee from './components/PayRoll/AddNewEmployee';

import StudentLedger from './components/Students/StudentLedger';
import PrintChallan from './components/FeeSystem/PrintChallan';
import CollectionReport from './components/FeeSystem/CollectionReport';
import CollectionReportDetail from './components/FeeSystem/CollectionReportDetail';
import AddAbsentFine from './components/FeeSystem/AddAbsentFine';
import AddOtherDuesFine from './components/FeeSystem/AddOtherDuesFine';
import MarkAsStudyComplete from './components/AnnualTasks/MarkAsStudyComplete';
import PromoteClasses from './components/AnnualTasks/PromoteClasses';
import BiodataLists from './components/Students/BiodataLists';
import ChangeStudentStatus from './components/FeeSystem/ChangeStudentStatus';
import AddClassesInSessions from './components/AnnualTasks/AddClassesInSessions';
import SMSAlertDashboard from './components/SMSAlert/SMSAlertDashboard';
import NewlyAdmittedStudents from './components/Reports/NewlyAdmittedStudents';
import ManageSalary from './components/PayRoll/ManageSalary';
import AttendenceSummary from './components/Reports/AttendenceSummary';
import EditEmployee from './components/PayRoll/EditEmployee';
import PaySlipPrint from './components/PayRoll/PaySlipPrint';
import PaySlips from './components/PayRoll/PaySlips';
import EmployeeReports from './components/PayRoll/EmployeeReports';
import ZeroReports from './components/PayRoll/ZeroReports';
import SummarySheet from './components/PayRoll/SummarySheet';
import ChangeStatus from './components/PayRoll/ChangeStatus';
import PrintAttendanceSummary from './components/StaffAttendance/PrintAttendanceSummary';
import PrintStAttendanceSummary from './components/Reports/PrintStAttendanceSummary';
import PrintSummarySheet from './components/PayRoll/PrintSummarySheet';
import PrintSummarySheetTwo from './components/PayRoll/PrintSummarySheetTwo';
import ManageLeaves from './components/PayRoll/ManageLeaves';
import AddArrear from './components/PayRoll/AddArrear';
import PrintEOBIList from './components/PayRoll/Prints/PrintEOBIList';
import PrintCollectionReport from './components/FeeSystem/PrintCollectionReport';
import PrintSWFList from './components/PayRoll/Prints/PrintSWFList';
import PrintTaxList from './components/PayRoll/Prints/PrintTaxList';
import PrintStaffList1 from './components/PayRoll/Prints/PrintStaffList1';
import PrintStaffList2 from './components/PayRoll/Prints/PrintStaffList2';
import PrintStaffList3 from './components/PayRoll/Prints/PrintStaffList3';
import PrintTaxList1 from './components/PayRoll/Prints/PrintTaxList1';
import PrintStaffList4 from './components/PayRoll/Prints/PrintStaffList4';
import PrintBankSalalryList from './components/PayRoll/Prints/PrintBankSalalryList';
import PrintMarkedAttendance from './components/StaffAttendance/PrintMarkedAttendance';
import EditStockItems from './components/StockManagement/EditStockItems';
import DefaultersListReport from './components/Reports/DefaultersListReport';
import ExamClasses from './components/ExaminationSystem/ExamClasses';
import OtherLoanDeductionsPrint from './components/PayRoll/OtherLoanDeductionsPrint';
import LoanDeductionsPrint from './components/PayRoll/LoanDeductionsPrint';
import UnpaidStudentPrint from './components/Reports/UnpaidStudentPrint';
import ConcessionPrint from './components/Reports/ConcessionPrint';
import StatusWisePrint from './components/Reports/StatusWisePrint';
import StudentFinePrint from './components/Reports/StudentFinePrint';
import StAttendenceSummary from './components/Reports/StAttendenceSummary';
import ReportsMenu from './components/Reports/ReportsMenu';

import AddLeave from './components/PayRoll/AddLeave';
import PrintLeaveRegister from './components/StaffAttendance/PrintLeaveRegister';
import PrintStudentCertificates from './components/Certificate/PrintStudentCertificates';
import StudentAttendanceDashboard from './components/StudentAttendance/StudentAttendanceDashboard';
import MarkSTAttendance from './components/StudentAttendance/MarkSTAttendance';
import StudentForm from './components/Shared/StudentForm';
import AttendanceSTSummary from './components/StudentAttendance/AttendanceSTSummary';
import StockItemsPrint from './components/StockManagement/StockItemsPrint';
import SendAlertToStaff from './components/SMSAlert/SendAlertToStaff';
import EditVoucher from './components/Accounts/EditVoucher';
import SendtoSinglePerson from './components/SMSAlert/SendtoSinglePerson';
import SendAlertToParents from './components/SMSAlert/SendAlertToParents';

import ReceiptPayment from './components/Accounts/ReceiptPayment';
import BioDataForms from './components/Shared/BioDataForms';
import StudentHistory from './components/Students/StudentHistory';
import PrintBiodataLists from './components/Students/PrintBiodataLists';
import StudentCard from './components/Shared/StudentCard';
import PrintNewAdmittedUnPaid from './components/Reports/PrintNewAdmittedUnPaid';
import PrintAttendenceSheetClassWise from './components/Setup/PrintAttendenceSheetClassWise';
import PrintBiodataLists1 from './components/Students/PrintBiodataLists1';
import PrintVouchersWebsite from './components/FeeSystem/PrintVouchersWebsite';
import PrintZeroReport from './components/PayRoll/PrintZeroReport';
import FeeReportingPrint from './components/Reports/FeeReportingPrint';
import PrintBioDataFormsforSection from './components/Setup/PrintBioDataFormsforSection';
import PrintManageLeaves from './components/PayRoll/Prints/PrintManageLeaves';
import PrintStockDetail from './components/StockManagement/PrintStockDetail';
import EditDesignation from './components/PayRoll/EditDesignation';
import AttendanceReport from './components/StaffAttendance/AttendanceReport';
import PrintAttendanceReport from './components/StaffAttendance/PrintAttendanceReport';
import AddNewDesignation from './components/PayRoll/AddNewDesignation';
import PrintCollectionReportDetail from './components/FeeSystem/PrintCollectionReportDetail';
import SalaryTypeValues from './components/PayRoll/SalaryTypeValues';



import TeacherMain from './components/Teacher/TeacherMain';
import TeacherDashboard from './components/Teacher/TeacherDashboard';
import ManageSections from './components/Teacher/ManageSections';
import TeacherLogin from './components/Teacher/TeacherLogin';
import MarkStudentAttendance from './components/Teacher/MarkStudentAttendance';
import ATSummary from './components/Teacher/ATSummary';
import UpdateProfile from './components/Teacher/UpdateProfile';
import Change_Password from './components/Teacher/Change_Password';
import UpdatePhoto from './components/Teacher/UpdatePhoto';
import Section_Incharge from './components/Teacher/Section_Incharge';
import Subject_Teacher from './components/Teacher/Subject_Teacher';
import PrintBusRoutes from './components/Setup/PrintBusRoutes';
import PrintRoutewiseStudentList from './components/Setup/PrintRoutewiseStudentList'
import PrintRoutewiseAttendenceSheet from "./components/Setup/PrintRoutewiseAttendenceSheet"
import PrintAllVouchers from './components/FeeSystem/PrintAllVouchers';
import PrintConcessionsRules from './components/Setup/PrintConcessionsRules';
import PrintManageClasses from './components/Setup/PrintManageClasses';
import PrintManageSections from './components/Setup/PrintManageSections';
import PrintFeeTypeList from './components/Setup/PrintFeeTypeList';
import PrintManageWings from './components/Setup/PrintManageWings';
import IssueBook from './components/Library/IssueBook';
import EditBook from './components/Library/EditBook';
import MonthDetails from './components/MonthlyTasks/MonthDetails';
import PrintMonthDetails from './components/MonthlyTasks/PrintMonthDetails';
import PrintExamDataEntryOptions from './components/ExaminationSystem/PrintExamDataEntryOptions';
import ExamDataSO from './components/ExaminationSystem/ExamDataSO';
import CloseThisExam from './components/ExaminationSystem/CloseThisExam';
import PrintExamDataSheet from './components/ExaminationSystem/PrintExamDataSheet';
import PrintExamDataSheetMultiple from './components/ExaminationSystem/PrintExamDataSheetMultiple';
import PrintResultCards from './components/ExaminationSystem/PrintResultCards';
import OralAssessmentReport1 from './components/Setup/OralAssessmentReport1';
import OralAssessmentReport2 from './components/Setup/OralAssessmentReport2';
import PrintAdvaceSearchReport from './components/Students/PrintAdvaceSearchReport';
import PrintNewlyAdmittedStudents from './components/Students/PrintNewlyAdmittedStudent';
import StudentProfileSearch from './components/Students/StudentProfileSearch';
import PrintStudentProfile from './components/Students/PrintStudentProfile';


//  IQRA

import IssuanceReport from './components/Library/IssuanceReport';
import ReturnReport from './components/Library/ReturnReport';
import PrintIssuanceReport from './components/Library/PrintIssuanceReport';
import PrintReturnReport from './components/Library/PrintReturnReport';
import PrintViewLibrary from './components/Library/PrintViewLibrary';
import PrintBuswiseAttendenceSheet from './components/Setup/PrintBuswiseAttendenceSheet';
import PrintBuswiseStudentList from './components/Setup/PrintBuswiseStudentList';
import ManageSettings from './components/Setup/ManageSettings';
import EditSettings from './components/Setup/EditSettings';
import GeneralLedger from './components/Accounts/GeneralLedger';
import PrintDetailSummarySheet from './components/PayRoll/PrintDetailSummarySheet';
import PrintOpeningBalance from './components/Accounts/PrintOpeningBalance';
import PrintManageAccount from './components/Accounts/PrintManageAccount';
import PrintTrialBalanceLevelWise from './components/Accounts/PrintTrialBalanceLevelWise';
import PrintTrialBalanceHeadWise from './components/Accounts/PrintTrialBalanceHeadWise';
import PrintIncomeExpenditure from './components/Accounts/PrintIncomeExpenditure';
import PrintGeneralLedger from './components/Accounts/PrintGeneralLedger';





import PrintStudentStrengthReports from './components/Reports/PrintStudentStrengthReports';




import StudentPanelDashboard from './components/StudentPanel/StudentPanelDashboard';
import StudentMain from './components/StudentPanel/Main';
import Student_Change_Password from './components/StudentPanel/Student_Change_Password';
import StudentUpdatePhoto from './components/StudentPanel/StudentUpdatePhoto';
import StudentUpdateProfile from './components/StudentPanel/StudentUpdateProfile';
import StudentPanelLedger from './components/StudentPanel/StudentLedger';
import SingleStudentSummary from './components/StudentPanel/SingleStudentSummary';
import IncomeExpenditure from './components/Accounts/IncomeExpenditure';
import ViewPaySlips from './components/Teacher/ViewPaySlips';
import EditRuleApplicable from './components/Setup/EditRuleApplicable';


import PrintManageUser from './components/Security/PrintManageUser';
import PrintRuleApplicable from './components/Setup/PrintRuleApplicable';
import AppointmentLetter from './components/PayRoll/AppointmentLetter';
import AppointmentLetterPrint from './components/PayRoll/AppointmentLetterPrint';
import FeePostingIssues from './components/Reports/FeePostingIssues';
import PrintPessiList from './components/PayRoll/Prints/PrintPessiList';
import PrintTransportList from './components/PayRoll/Prints/PrintTransportList';
import ImportStudent from './components/Students/ImportStudent';






// import ReturnReport from './components/Library/ReturnedReport';



function Index() {

  return (

    <BrowserRouter>


      <Routes>






        <Route path="" element={<Login />} ></Route>
        <Route path="/" element={<Login />} ></Route>
        <Route path="/login" element={<Login />} ></Route>


        <Route path="/Payroll/PrintSummarySheet" element={<PrintSummarySheet />} />
        <Route path="/Payroll/PrintDetailSummarySheet" element={<PrintDetailSummarySheet />} />
        <Route path="/Payroll/PrintSummarySheet2" element={<PrintSummarySheetTwo />} />
        <Route path="/Payroll/PrintZeroReport" element={<PrintZeroReport />} />
        <Route path="/Payroll/EmployeeReports/PrintEOBIList" element={<PrintEOBIList />} />
        <Route path="/Payroll/EmployeeReports/PrintPessiList" element={<PrintPessiList />} />
        <Route path="/Payroll/EmployeeReports/PrintTransportList" element={<PrintTransportList />} />
        <Route path="/PayRoll/EmployeeReports/PrintSWFList" element={<PrintSWFList />} />
        <Route path="/PayRoll/EmployeeReports/PrintTaxList" element={<PrintTaxList />} />
        <Route path="/PayRoll/EmployeeReports/PrintTaxList1" element={<PrintTaxList1 />} />
        <Route path="/PayRoll/EmployeeReports/PrintStaffList1" element={<PrintStaffList1 />} />
        <Route path="/PayRoll/EmployeeReports/PrintStaffList2" element={<PrintStaffList2 />} />
        <Route path="/PayRoll/EmployeeReports/PrintStaffList3" element={<PrintStaffList3 />} />
        <Route path="/PayRoll/EmployeeReports/PrintStaffList4" element={<PrintStaffList4 />} />
        <Route path="/PayRoll/OtherLoanDeductionsPrint" element={<OtherLoanDeductionsPrint />} />
        <Route path="/PayRoll/LoanDeductionsPrint" element={<LoanDeductionsPrint />} />
        <Route path="/PayRoll/PrintBankSalalryList" element={<PrintBankSalalryList />} />
        <Route path="/PayRoll/PrintManageLeaves" element={<PrintManageLeaves />} />
        <Route path="/Payroll/PaySlip/" element={<PaySlipPrint />}></Route>




        <Route path="/StockManagement/StockItemsPrint" element={<StockItemsPrint />} />
        <Route path="/StockManagement/PrintStockDetail" element={<PrintStockDetail />} ></Route>



        <Route path="/Students/PrintBiodataLists" element={<PrintBiodataLists />} />
        <Route path="/Students/PrintBiodataLists1" element={<PrintBiodataLists1 />} />
        <Route path="/students/PrintStudentProfile" element={<PrintStudentProfile />} />
        <Route path="/students/AdvanceSearch/print-advance-search" element={<PrintAdvaceSearchReport />} />
        <Route path="/students/PrintVoucher/" element={<PrintChallan />}></Route>
        <Route path="/students/PrintVouchersWebsite/" element={<PrintVouchersWebsite />}></Route>



        <Route path="/StaffAttendance/PrintMarkedAttendance" element={<PrintMarkedAttendance />} />
        <Route path="/StaffAttendance/PrintAttendanceSummary" element={<PrintAttendanceSummary />}></Route>
        <Route path="/StaffAttendance/PrintReport" element={<PrintAttendanceReport />} ></Route>
        <Route path="/StaffAttendance/PrintLeaveRegister" element={<PrintLeaveRegister />} />







        <Route path="/Reports/UnpaidStudentPrint" element={<UnpaidStudentPrint />} />
        <Route path="/Reports/ConcessionPrint" element={<ConcessionPrint />} />
        <Route path="/Reports/StatusWisePrint" element={<StatusWisePrint />} />
        <Route path="/Reports/PrintStAttendanceSummary" element={<PrintStAttendanceSummary />} />
        <Route path="/Reports/StudentFinePrint" element={<StudentFinePrint />} />
        <Route path="/Reports/PrintNewAdmittedUnPaid" element={<PrintNewAdmittedUnPaid />} />
        <Route path='/Reports/PrintNewlyAdmittedStudents' element={<PrintNewlyAdmittedStudents />} />
        <Route path="/Reports/DefaultersListReport" element={<DefaultersListReport />} />
        <Route path="/Reports/StruckoffList" element={<StruckoffList />} />
        <Route path="/Reports/SectionWiseList/:id" element={<SectionWiseList />} ></Route>

        {/* exams */}
        <Route path="/ExaminationSystem/ReportPrintExamDataSheet" element={<PrintExamDataSheet />} />
        <Route path="/ExaminationSystem/ReportPrintExamDataSheetMultiple" element={<PrintExamDataSheetMultiple />} />
        <Route path="/ExaminationSystem/PrintResultCards" element={<PrintResultCards />} />

        <Route path="/FeeSystem/PrintCollectionReport" element={<PrintCollectionReport />} />
        <Route path="/FeeSystem/PrintCollectionReportDetail" element={<PrintCollectionReportDetail />} />







        <Route path="/Setup/BioDataForms" element={<BioDataForms />} />
        <Route path="/Setup/PrintStudentCards/:id" element={<PrintStudentCards />} />
        <Route path="/Setup/PrintAttendenceSheetClassWise/:id" element={<PrintAttendenceSheetClassWise />} />
        <Route path="/Setup/PrintRoutewise-StudentList" element={<PrintRoutewiseStudentList />} />
        <Route path="/Setup/PrintBuswise-StudentList" element={<PrintBuswiseStudentList />} />
        <Route path="/Setup/PrintRoutewise-AttendenceSheet" element={<PrintRoutewiseAttendenceSheet />} />
        <Route path="/Setup/PrintBuswise-AttendenceSheet" element={<PrintBuswiseAttendenceSheet />} />
        <Route path="/Setup/PrintConcessionRules" element={<PrintConcessionsRules />} />
        <Route path="/Setup/PrintManageClasses" element={<PrintManageClasses />} />
        <Route path="/Setup/PrintManageSections" element={<PrintManageSections />} />
        <Route path="/Setup/PrintFeeTypes" element={<PrintFeeTypeList />} />
        <Route path="/Setup/PrintManageWings" element={<PrintManageWings />} />
        <Route path="/Setup/print/BusRoutes" element={<PrintBusRoutes />} />
        <Route path="/Setup/PrintAttendenceSheet/:id" element={<PrintAttendenceSheet />} />
        <Route path="/Setup/Absenteelist/:id" element={<Absenteelist />} />
        <Route path="/Setup/PrintBioDataForms/:id" element={<PrintBioDataForms />} />
        <Route path="/Setup/PrintBioDataFormsforSection/:id" element={<PrintBioDataFormsforSection />} />
        <Route path="/Setup/PrintTestPerforma/:id" element={<PrintTestPerforma />} />
        <Route path="/Setup/OralAssessmentReport1/:id" element={<OralAssessmentReport1 />} />
        <Route path="/Setup/OralAssessmentReport2/:id" element={<OralAssessmentReport2 />} />





        <Route path="/Certificate/PrintStudentCertificates" element={<PrintStudentCertificates />} />
        <Route path="/MonthlyTasks/MonthDetails/PrintMonthDetails" element={<PrintMonthDetails />} />


        <Route path="/payroll/appointment-letter/print" element={<AppointmentLetterPrint />} />

        <Route path="/" element={<Main />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/StudentForm" element={<StudentForm id="2" />} />

          <Route path="/students/StudentDashboard" element={<StudentDashboard />} />
          <Route path="/students/AddStudent" element={<AddStudent />} />
          <Route path="/students/ImportStudent" element={<ImportStudent />} />
          <Route path="/students/EditStudent/:id" element={<EditStudent />} />
          <Route path="/students/StudentHistory/:id" element={<StudentHistory />} />

          <Route path="/students/SearchStudents" element={<SearchStudents />} />
          <Route path="/students/AdmitStudent" element={<AdmitStudent />} />
          <Route path="/students/NewStudentList" element={<NewStudentList />} />
          <Route path="/students/ChangeRegistrationNo" element={<ChangeRegistrationNo />} />
          <Route path="/Students/AdvanceSearch" element={<AdvanceSearch />} />
          <Route path="/Students/StudentLedger/:id" element={<StudentLedger />} />
          <Route path="/Students/BiodataLists" element={<BiodataLists />} />
          <Route path="/Students/StudentCard" element={<StudentCard />} />

          <Route path="/student/StudentProfileSearch" element={<StudentProfileSearch />} />


          <Route path="/AnnualTasks/AnnualTasksDashboard" element={<AnnualTasksDashboard />} />
          <Route path="/AnnualTasks/AddSessions" element={<AddSessions />} />
          <Route path="/AnnualTasks/ManageSessions" element={<ManageSessions />} />
          <Route path="/AnnualTasks/EditSession/:id" element={<EditSession />} />
          <Route path="/AnnualTasks/MarkAsStudyComplete" element={<MarkAsStudyComplete />} />
          <Route path="/AnnualTasks/PromoteClasses" element={<PromoteClasses />} />
          <Route path="/AnnualTasks/AddClassesInSessions" element={<AddClassesInSessions />} />

          <Route path="SMSAlert/SMSAlertDashboard" element={<SMSAlertDashboard />} />
          <Route path="SMSAlert/SendAlertToStaff" element={<SendAlertToStaff />} />
          <Route path="SMSAlert/SendtoSinglePerson" element={<SendtoSinglePerson />} />
          <Route path="SMSAlert/SendAlertToParents" element={<SendAlertToParents />} />


          <Route path="/Security/SecurityDashboard" element={<SecurityDashboard />} />
          <Route path="/Security/AddNewUser" element={<AddNewUser />} />
          <Route path="/Security/AddGroup" element={<AddGroup />} />
          <Route path="/Security/GroupManagement" element={<GroupManagement />} />
          <Route path="/Security/ManageUsers" element={<ManageUsers />} />
          <Route path="/Security/EditUser/:id" element={<EditUser />} />
          <Route path="/Security/EditGroup/:id" element={<EditGroup />} />
          <Route path="/Security/RightsAssignment/:id" element={<RightsAssignment />} />
          <Route path="/Security/ChangePassword" element={<ChangePassword />} />
          <Route path="/Setup/SetupDashboard" element={<SetupDashboard />} />
          <Route path="/no-access" element={<NoAccess />} />
          <Route path="/StaffAttendance/Report" element={<AttendanceReport />} />




          <Route path="/Setup/SchoolInfo" element={<SchoolInfo />} />
          <Route path="/Setup/ManageWings" element={<ManageWings />} />
          <Route path="/Setup/ManageClasses" element={<ManageClasses />} />
          <Route path="/Setup/ManageSection" element={<ManageSection />} />
          <Route path="/Setup/ManageFeeTypes" element={<ManageFeeTypes />} />
          <Route path="/Setup/ManageBusRoutes" element={<ManageBusRoutes />} />
          <Route path="/Setup/ManageConcession" element={<ManageConcession />} />
          <Route path="/Setup/EditRuleApplicable/:id" element={<EditRuleApplicable />} />
          <Route path="/Setup/ManageBankAccounts" element={<ManageBankAccounts />} />
          <Route path="/Setup/ManageSettings" element={<ManageSettings />} />
          <Route path="/Setup/AddWings" element={<AddWings />} />
          <Route path="/Setup/AddClass" element={<AddClass />} />
          <Route path="/Setup/EditClass/:id" element={<EditClass />} />

          <Route path="/Setup/AddFeeType" element={<AddFeeType />} />
          <Route path="/Setup/AddBankAcc" element={<AddBankAcc />} />
          <Route path="/Setup/AddSection" element={<AddSection />} />
          <Route path="/Setup/UpdateSection/:id" element={<UpdateSection />} />
          <Route path="/Setup/AddBusRoutes" element={<AddBusRoutes />} />
          <Route path="/Setup/ManageBankAccounts" element={<ManageBankAccounts />} />
          <Route path="/Setup/AddConcession" element={<AddConcession />} />
          <Route path="/Setup/Editfeetype/:id" element={<EditFeeType />} />
          <Route path="/Setup/EditConcession/:id" element={<EditConcession />} />
          <Route path="/Setup/EditBankAcc/:id" element={<EditBankAcc />} />
          <Route path="/Setup/EditSetting/:id" element={<EditSettings />} />

          <Route path="/Setup/EditBusRoutes/:id" element={<EditBusRoutes />} />
          <Route path="/Setup/EditWings/:id" element={<EditWings />} />


          <Route path="/Payroll/PayrollDashboard" element={<PayrollDashboard />} />
          <Route path="/Payroll/ManageEmployee" element={<ManageEmployee />} />
          <Route path="/Payroll/ManageDesignations" element={<ManageDesignations />} />
          <Route path="/Payroll/ManageEmployeeTypes" element={<ManageEmployeeTypes />} />
          <Route path="/Payroll/AddEmployeeType" element={<AddEmployeeType />} />
          <Route path="/Payroll/EditEmployeeType/:id" element={<EditEmployeeType />} />
          <Route path="/Payroll/AddSalary" element={<AddSalary />} />
          <Route path="/Payroll/BankSalalryList" element={<BankSalalryList />} />
          <Route path="/Payroll/SalaryTypeValues/:id" element={<SalaryTypeValues />} />
          <Route path="/Payroll/CreateSalary" element={<CreateSalary />} />
          <Route path="/Payroll/ManageSalaryTypes" element={<ManageSalaryTypes />} />
          <Route path="/Payroll/IncreaseDecreaseSalary" element={<IncreaseDecreaseSalary />} />
          <Route path="/Payroll/AddSalaryType" element={<AddSalaryType />} />
          <Route path="/Payroll/EditSalaryType/:id" element={<EditSalaryType />} />
          <Route path="/Payroll/ManageLoanDeduction" element={<ManageLoanDeduction />} />
          <Route path="/Payroll/AddLoanDeduction" element={<AddLoanDeduction />} />
          <Route path="/Payroll/EditLoanDeduction/:id" element={<EditLoanDeduction />} />
          <Route path="/Payroll/EditOtherLoanDeduction/:id" element={<EditOtherLoanDeduction />} />
          <Route path="/Payroll/AddOtherDeduction" element={<AddOtherDeduction />} />
          <Route path="/Payroll/ManageOtherLoanDeductions" element={<ManageOtherLoanDeductions />} />
          <Route path="/Payroll/EmployeeLedger/:id" element={<EmployeeLedger />} />
          <Route path="/Payroll/AddNewEmployee" element={<AddNewEmployee />} />
          <Route path="/Payroll/EditEmployee/:id" element={<EditEmployee />} />
          <Route path="/Payroll/ManageSalary/:id" element={<ManageSalary />} />
          <Route path="/Payroll/PaySlips" element={<PaySlips />} />
          <Route path="/payroll/appointment-letter" element={<AppointmentLetter />} />
          <Route path="/Payroll/EmployeeReports" element={<EmployeeReports />} />
          <Route path="/Payroll/ZeroReports" element={<ZeroReports />} />
          <Route path="/Payroll/SummarySheet" element={<SummarySheet />} />
          <Route path="/Payroll/ChangeStatus/:id" element={<ChangeStatus />} />
          <Route path="/Payroll/ManageLeaves" element={<ManageLeaves />} />
          <Route path="/Payroll/AddArrear" element={<AddArrear />} />
          <Route path="/PayRoll/AddLeave/:id" element={<AddLeave />} />

          <Route path="/Payroll/EditDesignation/:id" element={<EditDesignation />} />
          <Route path="/Payroll/AddNewDesignation" element={<AddNewDesignation />} />



          <Route path="/Library/LibraryDashboard" element={<LibraryDashboard />} />
          <Route path="/Library/AddBook" element={<AddBook />} />
          <Route path="/Library/EditBook/:id" element={<EditBook />} />
          <Route path="/Library/IssueBook/:id" element={<IssueBook />} />
          <Route path="/Library/ViewLibrary" element={<ViewLibrary />} />
          <Route path="/Library/IssuanceReport" element={<IssuanceReport />} />
          <Route path="/Library/ReturnReport" element={<ReturnReport />} />



          <Route path="/FeeSystem/FeeSystemDashboard" element={<FeeSystemDashboard />} />
          <Route path="/FeeSystem/PrintVouchers" element={<PrintVouchers />} />
          <Route path="/FeeSystem/PrintAllVouchers" element={<PrintAllVouchers />} />
          <Route path="/FeeSystem/AddBankScroll" element={<AddBankScroll />} />
          <Route path="/FeeSystem/CollectionReport" element={<CollectionReport />} />
          <Route path="/FeeSystem/CollectionReportDetail/:BenchNo/:Dated" element={<CollectionReportDetail />} />
          <Route path="/FeeSystem/AddAbsentFine" element={<AddAbsentFine />} />
          <Route path="/FeeSystem/AddOtherDuesFine" element={<AddOtherDuesFine />} />
          <Route path="/FeeSystem/ChangeStudentStatus" element={<ChangeStudentStatus />} />

          <Route path="/Reports/ReportsDashboard" element={<ReportDashboard />} />
          <Route path="/Reports/AccountHeadwiseReport" element={<AccountHeadwiseReport />} />

          <Route path="/Reports/ConcessionReport" element={<ConcessionReport />} />
          <Route path="/Reports/DefaultersList" element={<DefaultersList />} />
          <Route path="/Reports/FeeCollectionDetail" element={<FeeCollectionDetail />} />
          <Route path="/Reports/FeeReporting" element={<FeeReporting />} />
          <Route path="/Reports/FeePostingIssues" element={<FeePostingIssues />} />
          <Route path="/Reports/NewAdmittedUnPaid" element={<NewAdmittedUnPaid />} />
          <Route path="/Reports/StatusWiseChange" element={<StatusWiseChange />} />
          <Route path="/Reports/StruckedStudents" element={<StruckedStudents />} />
          <Route path="/Reports/StudentFineReport" element={<StudentFineReport />} />
          <Route path="/Reports/StudentStrengthReport" element={<StudentStrengthReport />} />
          <Route path="/Reports/UnpaidStudent" element={<UnpaidStudent />} />
          <Route path="/Reports/NewlyAdmittedStudents" element={<NewlyAdmittedStudents />} />
          <Route path="/Reports/AttendenceSummary" element={<AttendenceSummary />} />
          <Route path="/Reports/StAttendenceSummary" element={<StAttendenceSummary />} />
          <Route path="/Reports/ReportsMenu" element={<ReportsMenu />} ></Route>






          <Route path="/MonthlyTasks/MonthlyTasksDashboard" element={<MonthlyTasksDashboard />} />
          <Route path="/MonthlyTasks/ManageMonth" element={<ManageMonth />} />
          <Route path="/MonthlyTasks/MonthDetails" element={<MonthDetails />} />
          <Route path="/MonthlyTasks/StartNewMonth" element={<StartNewMonth />} />

          <Route path="/Accounts/AccountsDashboard" element={<AccountsDashboard />} />
          <Route path="/Accounts/ManageAccounts" element={<ManageAccount />} />
          <Route path="/Accounts/ChangeYear" element={<ChangeYear />} />
          <Route path="/Accounts/AddNewAccount" element={<AddNewAccount />} />
          <Route path="/Accounts/ManageVouchers" element={<ManageVouchers />} />
          <Route path="/Accounts/OpeningBalances" element={<OpeningBalances />} />
          <Route path="/Accounts/GeneralLedger/:accountcode" element={<GeneralLedger />} />
          <Route path="/Accounts/AddVoucher" element={<AddVoucher />} />
          <Route path="/Accounts/EditVoucher/:id" element={<EditVoucher />} />
          <Route path="/Accounts/TrialBalanceLevelWise" element={<TrialBalanceLevelWise />} />
          <Route path="/Accounts/TrialBalanceHeadWise" element={<TrialBalanceHeadWise />} />
          <Route path="/Accounts/IncomeExpenditure" element={<IncomeExpenditure />} />
          <Route path="/Accounts/BudgetHeadWise" element={<BudgetHeadWise />} />
          <Route path="/Accounts/EditOpeningBalance/:id" element={<EditOpeningBalance />} />
          <Route path="/Accounts/ReceiptPayment" element={<ReceiptPayment />} />

          <Route path="/Certificate/CertificateDashboard" element={<CertificateDashboard />} />
          <Route path="/Certificate/PrintCertificates" element={<PrintCertificates />} />


          <Route path="/ExaminationSystem/ExaminationDashboard" element={<ExaminationDashboard />} />
          <Route path="/ExaminationSystem/AddExaminations" element={<AddExaminations />} />
          <Route path="/ExaminationSystem/AddSubjectType" element={<AddSubjectType />} />
          <Route path="/ExaminationSystem/AddSubject" element={<AddSubject />} />
          <Route path="/ExaminationSystem/CloseExam" element={<CloseExam />} />
          <Route path="/ExaminationSystem/DataEntry" element={<DataEntry />} />
          <Route path="/ExaminationSystem/ExamDataEntryOptions/:id" element={<ExamDataEntryOptions />} />
          <Route path="/ExaminationSystem/ExamDataSheetOptions" element={<ExamDataSheetOptions />} />
          <Route path="/ExaminationSystem/ManageClassSubjects" element={<ManageClassSubjects />} />
          <Route path="/ExaminationSystem/ManageExamClasses" element={<ManageExamClasses />} />
          <Route path="/ExaminationSystem/ManageExaminations" element={<ManageExaminations />} />
          <Route path="/ExaminationSystem/ManageSubjects" element={<ManageSubjects />} />
          <Route path="/ExaminationSystem/ManageSubjectTypes" element={<ManageSubjectTypes />} />
          <Route path="/ExaminationSystem/OldResults" element={<OldResults />} />
          <Route path="/ExaminationSystem/EditSubjectType/:id" element={<EditSubjectType />} />
          <Route path="/ExaminationSystem/EditSubject/:id" element={<EditSubject />} />
          <Route path="/ExaminationSystem/EditExam/:id" element={<EditExam />} />
          <Route path="/ExaminationSystem/ExamClasses/:id" element={<ExamClasses />} />
          <Route path="/ExaminationSystem/ExamDataSO" element={<ExamDataSO />} />
          <Route path="/ExaminationSystem/CloseThisExam" element={<CloseThisExam />} />







          <Route path="/StockManagement/StocksDashboard" element={<StocksDashboard />} />
          <Route path="/StockManagement/AddStockCategory" element={<AddStockCategory />} />
          <Route path="/StockManagement/IssueItems/:id" element={<IssueItems />} />
          <Route path="/StockManagement/ManageStockCategory" element={<ManageStockCategory />} />
          <Route path="/StockManagement/StockDetail/:id" element={<StockDetail />} />
          <Route path="/StockManagement/EditStockItems/:id" element={<EditStockItems />} />


          <Route path="/StaffAttendance/StaffAttendanceDashboard" element={<StaffAttendanceDashboard />} />
          <Route path="/StaffAttendance/MarkAttendance" element={<MarkAttendance />} />
          <Route path="/StaffAttendance/AttendanceSummary" element={<AttendanceSummary />} />
          <Route path="/StaffAttendance/LeaveRegister" element={<LeaveRegister />} />
          <Route path="/StaffAttendance/AttendanceRights" element={<AttendanceRights />} />

          <Route path="/StudentAttendance/StudentAttendanceDashboard" element={<StudentAttendanceDashboard />} />
          <Route path="/StudentAttendance/MarkSTAttendance" element={<MarkSTAttendance />} />
          <Route path="/StudentAttendance/AttendanceSTSummary" element={<AttendanceSTSummary />} />
        </Route>


        <Route path="/StockManagement/PrintStockDetail" element={<PrintStockDetail />} ></Route>


        <Route path='/Accounts/PrintOpeningBalance' element={<PrintOpeningBalance />} />
        <Route path='/Accounts/PrintManageAccount' element={<PrintManageAccount />} />
        <Route path='/Accounts/PrintTrialBalanceLevelWise' element={<PrintTrialBalanceLevelWise />} />
        <Route path='/Accounts/PrintTrialBalanceHeadWise' element={<PrintTrialBalanceHeadWise />} />
        <Route path='/Accounts/PrintIncomeExpenditure' element={<PrintIncomeExpenditure />} />
        <Route path='/Accounts/PrintGeneralLedger' element={<PrintGeneralLedger />} />








        <Route path="/ExaminationSystem/PrintExamDataSheet" element={<PrintExamDataEntryOptions />} />
        <Route path="/PrintReports/SectionWiseClassList/:id" element={<SectionWiseClassList />} ></Route>
        <Route path="/PrintReports/Classwiseattendancesheet/:id" element={<Classwiseattendancesheet />} ></Route>
        <Route path="/PrintReports/AbsentListClassWise/:id" element={<AbsentListClassWise />} ></Route>
        <Route path="/PrintReports/PrintBioDataFormsList/:id" element={<PrintBioDataFormsList />} ></Route>
        <Route path="/PrintReports/PrintStudentCardsList/:id" element={<PrintStudentCardsList />} ></Route>
        <Route path="/PrintReports/PrintTestPerformaList/:id" element={<PrintTestPerformaList />} ></Route>
        <Route path="/PrintReports/RPTAwardListClass/:id" element={<RPTAwardListClass />} ></Route>
        <Route path="/PrintReports/PrintOralAssessmentReport1/:id" element={<PrintOralAssessmentReport1 />} ></Route>
        <Route path="/PrintReports/PrintOralAssessmentReport1Urdu/:id" element={<PrintOralAssessmentReport1Urdu />} ></Route>
        <Route path="/PrintReports/PrintOralAssessmentReport2Urdu/:id" element={<PrintOralAssessmentReport2Urdu />} ></Route>

        <Route path="/Library/PrintIssuanceReport" element={<PrintIssuanceReport />} />
        <Route path="/Library/PrintReturnReport" element={<PrintReturnReport />} />
        <Route path="/Library/PrintViewLibrary" element={<PrintViewLibrary />} />











        <Route path="/PrintReports/RPTAwardList/:id" element={<RPTAwardList />} ></Route>

        <Route path="/Reports/FeeReportingPrint" element={<FeeReportingPrint />} ></Route>

        <Route path="/Reports/PrintStudentStrengthReports" element={<PrintStudentStrengthReports />} ></Route>




        <Route path="/Security/PrintManageUser" element={<PrintManageUser />} ></Route>
        <Route path="/setup/editruleapplicable-print" element={<PrintRuleApplicable />} ></Route>















        <Route path="/teacher" element={<TeacherMain />}>
          <Route path="/teacher/dashboard" element={<TeacherDashboard />} />
          <Route path="/teacher/change-password" element={<Change_Password />} />
          <Route path="/teacher/update-photo" element={<UpdatePhoto />} />
          <Route path="/teacher/section-incharge" element={<Section_Incharge />} />
          <Route path="/teacher/subject-teacher" element={<Subject_Teacher />} />
          <Route path="/teacher/manage-sections" element={<ManageSections />} />
          <Route path="/teacher/MarkStudentAttendance" element={<MarkStudentAttendance />} />
          <Route path="/teacher/atsummary" element={<ATSummary />} />
          <Route path="/teacher/UpdateProfile" element={<UpdateProfile />} />
          <Route path="/teacher/ViewPaySlips" element={<ViewPaySlips />} />
        </Route>


        <Route path="/student-panel" element={<StudentMain />}>
          <Route path="/student-panel/dashboard" element={<StudentPanelDashboard />} />
          <Route path="/student-panel/change-password" element={<Student_Change_Password />} />
          <Route path="/student-panel/update-photo" element={<StudentUpdatePhoto />} />
          <Route path="/student-panel/update-profile" element={<StudentUpdateProfile />} />
          <Route path="/student-panel/student-ledger" element={<StudentPanelLedger />} />
          <Route path="/student-panel/student-summary" element={<SingleStudentSummary />} />
        </Route>

      </Routes>
    </BrowserRouter>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index />);