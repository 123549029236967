import React, { useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import RightsCheck from '../General/RightsCheck';
import { scrollToTop } from '../Shared/ScrollOnTop';

const ReportsMenu = () => {


    return (
        <>
            <RightsCheck />
            <nav className={`navbar navbar-expand-lg navbar-module MenuSticky`}>
                <div className="container-fluid">
                <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#NavbarSecondary"
            aria-controls="NavbarSecondary"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
                    <div className="collapse navbar-collapse"id='NavbarSecondary'>
                        <ul className="navbar-nav m-auto">
                            <li className="nav-item">
                                <NavDropdown title="Student">
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/Reports/StruckoffList">Strucked Students</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/Reports/DefaultersList">Defaulters List</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/Reports/UnpaidStudent">Unpaid Student</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/Reports/NewlyAdmittedStudents">Newly Admitted Students</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/Reports/StudentFineReport">Student Fine</NavLink>
                                    <NavLink onClick={scrollToTop} className="dropdown-item" to="/Reports/FeePostingIssues">Scroll Issues</NavLink>
                                </NavDropdown>
                            </li>


                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/Reports/StatusWiseChange">Status Wise Change</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/Reports/ConcessionReport">Concession</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/Reports/FeeReporting">Fee Reporting</NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/Reports/NewAdmittedUnPaid">New Admitted UnPaid</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/Reports/StudentStrengthReport">Student Strength</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/Reports/AccountHeadwiseReport">Account Headwise</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/Reports/AttendenceSummary">Attendence Summary</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav >
        </>
    );
}

export default ReportsMenu;
