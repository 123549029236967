import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Monthly_navbar from "./Monthlytasks_navbar";
import { Form, Spinner } from "react-bootstrap";
import MonthsDropDown from "../Shared/MonthsDropDown";
import YearsDropDown from "../Shared/YearsDropDown";
import moment from "moment";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { scrollToTop } from "../Shared/ScrollOnTop";
import config from "../../Config";
const StartNewMonth = () => {
  document.title = "AGS - School Management System";
  const [Month, setMonth] = useState(moment().format("M"));
  const [Year, setYear] = useState(moment().format("YYYY"));
  const [MonthForConcessions, setMonthForConcessions] = useState(
    moment().format("M")
  );
  const [YearForConcessions, setYearForConcessions] = useState(
    moment().format("YYYY")
  );
  const [MonthForArrears, setMonthForArrears] = useState(moment().format("M"));
  const [YearForArrears, setYearForArrears] = useState(moment().format("YYYY"));
  const [MonthForPostAdvance, setMonthForPostAdvance] = useState(
    moment().format("M")
  );
  const [YearForPostAdvance, setYearForPostAdvance] = useState(
    moment().format("YYYY")
  );
  const [MonthForDeleteFee, setMonthForDeleteFee] = useState(
    moment().format("M")
  );
  const [YearForDeleteFee, setYearForDeleteFee] = useState(
    moment().format("YYYY")
  );
  const [MonthForPostFine, setMonthForPostFine] = useState(
    moment().format("M")
  );
  const [YearForPostFine, setYearForPostFine] = useState(
    moment().format("YYYY")
  );

  const [MonthForPostAbsentFine, setMonthForPostAbsentFine] = useState(
    moment().format("M")
  );
  const [YearForPostAbsentFine, setYearForPostAbsentFine] = useState(
    moment().format("YYYY")
  );

  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [SessionID, setSessionID] = useState(
    localStorage.getItem("DefaultSession")
  );

  const [TransportFee, setTransportFee] = useState(false);

  const [loading, setLoading] = useState(false);
  const [Concessionsloading, setConcessionsloading] = useState(false);
  const [Arrearsloading, setArrearsloading] = useState(false);
  const [PostAdvanceloading, setPostAdvanceloading] = useState(false);
  const [DeleteFeeloading, setDeleteFeeloading] = useState(false);
  const [PostFineloading, setPostFineloading] = useState(false);
  const [PostAbsentFineloading, setPostAbsentFineloading] = useState(false);
  const [DateForPostFine, setDateForPostFine] = useState();

  const handleMonth = () => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      SelectedMonth: Month,
      FYear: Year,
      SessionID: SessionID,
      AddTransportFee: TransportFee,
    };
    const api_config = {
      method: "post",
      url: config.base_url + "/FeeSystem/StartNewMonth",

      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleConcessions = () => {
    setConcessionsloading(true);
    const data = {
      AccessKey: AccessKey,
      MemberType: MemberType,
      UserID: UserID,
      SelectedMonth: MonthForConcessions,
      FYear: YearForConcessions,
      SessionID: SessionID,
    };

    const api_config = {
      method: "post",
      url: config.base_url + "FeeSystem/ApplyConcession",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setConcessionsloading(false);
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setConcessionsloading(false);
        }
      })
      .catch(function (error) {
        setConcessionsloading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleArrears = () => {
    setArrearsloading(true);
    const data = {
      AccessKey: AccessKey,
      MemberType: MemberType,
      UserID: UserID,
      SelectedMonth: MonthForArrears,
      FYear: YearForArrears,
    };

    const api_config = {
      method: "post",
      url: config.base_url + "FeeSystem/PostArrears",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setArrearsloading(false);
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setArrearsloading(false);
        }
      })
      .catch(function (error) {
        setArrearsloading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handlePostAdvance = () => {
    setPostAdvanceloading(true);
    const data = {
      AccessKey: AccessKey,
      MemberType: MemberType,
      UserID: UserID,
      SelectedMonth: MonthForPostAdvance,
      FYear: YearForPostAdvance,
    };

    const api_config = {
      method: "post",
      url: config.base_url + "FeeSystem/PostAdvance",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log(data);
    axios(api_config)
      .then(function (response) {
        //console.log("response", response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setPostAdvanceloading(false);
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setPostAdvanceloading(false);
        }
      })
      .catch(function (error) {
        setPostAdvanceloading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleDeleteFee = () => {
    setDeleteFeeloading(true);
    const data = {
      AccessKey: AccessKey,
      MemberType: MemberType,
      UserID: UserID,
      SelectedMonth: MonthForDeleteFee,
      FYear: YearForDeleteFee,
    };

    const api_config = {
      method: "post",
      url: config.base_url + "FeeSystem/DeleteFee",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log(data);
    axios(api_config)
      .then(function (response) {
        //console.log("response", response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setDeleteFeeloading(false);
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setDeleteFeeloading(false);
        }
      })
      .catch(function (error) {
        setDeleteFeeloading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handlePostFine = () => {
    setPostFineloading(true);
    const data = {
      AccessKey: AccessKey,
      MemberType: MemberType,
      UserID: UserID,
      SelectedMonth: MonthForPostFine,
      FYear: YearForPostFine,
      DateForPostFine: DateForPostFine,
    };

    const api_config = {
      method: "post",
      url: config.base_url + "FeeSystem/PostMonthlyFine",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log(data);
    axios(api_config)
      .then(function (response) {
        //console.log("response", response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setPostFineloading(false);
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setPostFineloading(false);
        }
      })
      .catch(function (error) {
        setPostFineloading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handlePostAbsentFine = () => {
    setPostAbsentFineloading(true);
    const data = {
      AccessKey: AccessKey,
      MemberType: MemberType,
      UserID: UserID,
      SelectedMonth: MonthForPostAbsentFine,
      FYear: YearForPostAbsentFine,
    };

    const api_config = {
      method: "post",
      url: config.base_url + "FeeSystem/PostMonthlyAbsentFine",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log(data);
    axios(api_config)
      .then(function (response) {
        //console.log("response", response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setPostAbsentFineloading(false);
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setPostAbsentFineloading(false);
        }
      })
      .catch(function (error) {
        setPostAbsentFineloading(false);
        toast.error("Network Error..", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <Monthly_navbar />
      <ToastContainer />

      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/MonthlyTasks/MonthlyTasksDashboard">
                      Monthly Task
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Start New Month
                  </li>
                </ol>
              </nav>
              <div className="page-title">Start New Month</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <Link
                  to="/MonthlyTasks/ManageMonth"
                  className="button button-white button-icon"
                >
                  Manage Month
                  <i className="ri-list-check"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="card mb-2">
            <div className="card-body p-md-4">
              <div className="bg-light p-md-4">
                <p className="mb-1">
                  Start New Month is a monthly task and will be perform to add
                  dues in the students ledger, just verify the date and click on
                  the button below, this will update the ledgers with current
                  feeses mentioned as monthly fee in classes defination.
                </p>
                <h5>
                  <b>Note:</b>
                </h5>
                <p className="mb-0">
                  This Process may take a little time to post enteries in the
                  system so do not press the button twice and wait for its
                  completion.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-2">
          <div className="card">
            <div className="card-body p-md-4">
              <div className="row align-items-end">
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Current Month</label>
                    <select
                      className="form-select"
                      onChange={(e) => setMonthForArrears(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      <MonthsDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Current Year</label>
                    <select
                      className="form-select"
                      onChange={(e) => setYearForArrears(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      <YearsDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-2 offset-md-6 text-end">
                  {Arrearsloading ? (
                    <Spinner animation="border" role="status"></Spinner>
                  ) : (
                    <button
                      type="button"
                      className="button button-primary w-100"
                      onClick={handleArrears}
                    >
                      Post Arrears
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-2">
          <div className="card">
            <div className="card-body p-md-4">
              <div className="row align-items-end">
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Current Month</label>
                    <select
                      className="form-select"
                      onChange={(e) => setMonthForPostAdvance(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      <MonthsDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Current Year</label>
                    <select
                      className="form-select"
                      onChange={(e) => setYearForPostAdvance(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      <YearsDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-2 offset-md-6 text-end">
                  {PostAdvanceloading ? (
                    <Spinner animation="border" role="status"></Spinner>
                  ) : (
                    <button
                      type="button"
                      className="button button-primary w-100"
                      onClick={handlePostAdvance}
                    >
                      Post Advance
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="card">
            <div className="card-body p-md-4">
              <div className="row align-items-center">
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Current Month</label>
                    <select
                      className="form-select"
                      onChange={(e) => setMonth(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      <MonthsDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Current Year</label>
                    <select
                      className="form-select"
                      onChange={(e) => setYear(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      <YearsDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Today</label>
                    <input
                      disabled
                      className="form-control"
                      value={moment().format("h:mm A")}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <Form.Group className="mt-4">
                    <Form.Check
                      required
                      label="Add Transport Fee:"
                      feedbackType="invalid"
                      checked={TransportFee}
                      onChange={(e) => setTransportFee(e.target.checked)}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-2 offset-md-1 text-end">
                  {loading ? (
                    <Spinner animation="border" role="status"></Spinner>
                  ) : (
                    <button
                      type="submit"
                      className="button button-primary w-100"
                      onClick={handleMonth}
                    >
                      Start This Month{" "}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-2">
          <div className="card">
            <div className="card-body p-md-4">
              <div className="row align-items-end">
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Current Month</label>
                    <select
                      className="form-select"
                      onChange={(e) => setMonthForConcessions(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      <MonthsDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Current Year</label>
                    <select
                      className="form-select"
                      onChange={(e) => setYearForConcessions(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      <YearsDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-2 offset-md-6 text-end">
                  {Concessionsloading ? (
                    <Spinner animation="border" role="status"></Spinner>
                  ) : (
                    <button
                      type="button"
                      className="button button-primary w-100"
                      onClick={handleConcessions}
                    >
                      Apply Concessions
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-2">
          <div className="card">
            <div className="card-body p-md-4">
              <div className="row align-items-end">
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Current Month</label>
                    <select
                      className="form-select"
                      onChange={(e) => setMonthForPostFine(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      <MonthsDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Current Year</label>
                    <select
                      className="form-select"
                      onChange={(e) => setYearForPostFine(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      <YearsDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label className="">Fine From Date:</label>
                    <input
                      type="date"
                      defaultValue={moment().format(config.date_format_input)}
                      className="form-control"
                      required
                      name="StartDate"
                      max={moment().format(config.date_format_input)}
                      onChange={(e) => setDateForPostFine(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-md-2 offset-md-4 text-end">
                  {PostFineloading ? (
                    <Spinner animation="border" role="status"></Spinner>
                  ) : (
                    <button
                      type="button"
                      className="button button-primary w-100"
                      onClick={handlePostFine}
                    >
                      Post Monthly Fine
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-2">
          <div className="card">
            <div className="card-body p-md-4">
              <div className="row align-items-end">
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Current Month</label>
                    <select
                      className="form-select"
                      onChange={(e) =>
                        setMonthForPostAbsentFine(e.target.value)
                      }
                    >
                      <option value="">Please Select</option>
                      <MonthsDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Current Year</label>
                    <select
                      className="form-select"
                      onChange={(e) => setYearForPostAbsentFine(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      <YearsDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-2 offset-md-6 text-end">
                  {PostAbsentFineloading ? (
                    <Spinner animation="border" role="status"></Spinner>
                  ) : (
                    <button
                      type="button"
                      className="button button-primary w-100"
                      onClick={handlePostAbsentFine}
                    >
                      Post Absent Fine
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-2">
          <div className="card">
            <div className="card-body p-md-4">
              <div className="row align-items-end">
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Current Month</label>
                    <select
                      className="form-select"
                      onChange={(e) => setMonthForDeleteFee(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      <MonthsDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Current Year</label>
                    <select
                      className="form-select"
                      onChange={(e) => setYearForDeleteFee(e.target.value)}
                    >
                      <option value="">Please Select</option>
                      <YearsDropDown />
                    </select>
                  </div>
                </div>
                <div className="col-md-2 offset-md-6 text-end">
                  {DeleteFeeloading ? (
                    <Spinner animation="border" role="status"></Spinner>
                  ) : (
                    <button
                      type="button"
                      className="button button-primary w-100"
                      onClick={handleDeleteFee}
                    >
                      Delete Posting
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartNewMonth;
