import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import user from "../../assets/images/user.png";

import Page from "../General/Page";

const PrintStudentProfile = () => {

    document.title ='Print Student Profile'

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);

    const [listOfLedger, setLedger] = useState([]);
    const [selectedlistOfLedger, setSelectedLedger] = useState({});

    const [StartDate, setStartDate] = useState("2023-01-01");
    const [EndDate, setEndDate] = useState("2025-06-01");
    const [Student, setStudent] = useState({});

    const [AttendenceList, setAttendenceList] = useState([]);
    const [History, setHistory] = useState([]);

    const urlParams = new URLSearchParams(window.location.search);
    const StudentCode = urlParams.get('StudentCode');

    let totalDebit = 0;
    let totalCredit = 0;

    useEffect(() => {


        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            Registration_No: StudentCode

        };

       //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + '/Student/GetStudentByRegistrationNo',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

       //console.log(data)
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setStudent(response.data.Student);
                    setLoading(false);
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setLoading(false);
                }

            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });


    }, []);

    useEffect(() => {


        if (!loading) {
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                pageNo: 1,
                pageSize: 100000,
                Student_ID: Student?.Student_ID,
                StartDate: StartDate,
                EndDate: EndDate,
            };

            var api_config = {
                method: "post",
                url: config.base_url + "Student/GetStudentLedger",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(api_config)
                .then(function (response) {
                    if (response.data.status_code === 1) {
                        setLedger(response.data.ListofRecords);



                    } else {
                        setLedger(null);

                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });


                    }
                })
                .catch(function (error) {
                   //console.log(error);
                });
        }


    }, [loading]);

    useEffect(() => {

        if (!loading) {
            const data = {
                UserID: UserID,
                AccessKey: AccessKey,
                Student_ID: Student?.Student_ID
            };

           //console.log(data);
            var api_config = {
                method: 'post',
                url: config.base_url + 'StudentAttendance/GetAttendanceByStudentID',
                headers: {
                    'Content-Type': 'application/json'
                },

                data: data
            };

           //console.log(data);
            axios(api_config)
                .then(function (response) {
                   //console.log(response.data);
                    if (response.data.status_code == 1) {
                        setAttendenceList(response.data.ListofRecords)

                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                })
                .catch(function (error) {
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
        }

    }, [loading]);

    useEffect(() => {


        if (!loading) {
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                ID: Student?.Student_ID,
            };

            var api_config = {
                method: "post",
                url: config.base_url + "Student/GetStudentByID",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(api_config)
                .then(function (response) {
                    if (response.data.status_code === 1) {
                        setHistory(response.data.ListofStatusHistory);




                    } else {
                        setLedger(null);

                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });


                    }
                })
                .catch(function (error) {
                   //console.log(error);
                });
        }


    }, [loading]);


    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            return listOfLedger.map((item, index) => {
                totalDebit += item.Debit;
                totalCredit += item.Credit;

                return (
                    <>
                        <tr key={index}>
                            <td>{item.Transaction_ID}</td>
                            <td>{moment(item.Transaction_Date).format("DD/MM/YYYY")}</td>
                            <td>{item.Fee_Code}</td>
                            <td>{item.Receipt_No}</td>
                            <td>{item.Description}</td>
                            <td>{item.Debit}</td>
                            <td>{item.Credit}</td>
                            <td>{item.Balance}</td>
                            <td>{item.Balance_Type}</td>

                        </tr>
                    </>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="8">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    return (
        <>
            <Page orientation="portrait" size="a4">
                {
                    loading ?
                        <Spinner animation="border" role="status"></Spinner>
                        :
                        <>
                            <table className="table table-sm table-bordered table-print white-space-wrap">
                                <thead>
                                    <tr>
                                        <th colSpan={20}>
                                            <PrintHeader />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ border: "transparent" }}>
                                        <td style={{ width: "75%" }}>
                                            <div>
                                                <h6 className=""><b>Registration Details</b></h6>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "150px" }}>Form No</td>
                                                            <td style={{ width: "10px" }}>:</td>
                                                            <td>{Student.Form_No}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Registration No</td>
                                                            <td>:</td>
                                                            <td>{Student.Registration_No}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Student ID</td>
                                                            <td>:</td>
                                                            <td>{Student.Student_ID}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Bay Form No</td>
                                                            <td>:</td>
                                                            <td>{Student.Bay_Form_No}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                        <td align="right" valign="top">
                                            {/* {Student.Image} */}
                                            <img src={user} />
                                        </td>
                                    </tr>
                                    <tr style={{ border: "transparent" }}>
                                        <td style={{ width: "75%" }}>
                                            <div>
                                                <h6 className=""><b>Basic Information</b></h6>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "150px" }}>Student Name</td>
                                                            <td style={{ width: "10px" }}>:</td>
                                                            <td>{Student.Student_Name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Father Name</td>
                                                            <td>:</td>
                                                            <td>{Student.Father_Name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Father CNIC No</td>
                                                            <td>:</td>
                                                            <td>{Student.Father_CNIC}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                        <td>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Gender</td>
                                                        <td>:</td>
                                                        <td>{Student.Gender ? 'Male' : 'Female'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Date of Birth</td>
                                                        <td>:</td>
                                                        <td>{moment(Student.Date_Of_Birth).format('DD/MM/YYYY')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Group</td>
                                                        <td>:</td>
                                                        <td>{Student?.SelectedGroup?.Group_Name}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr style={{ border: "transparent" }}>
                                        <td colSpan={3}>
                                            <h6 className=""><b>Contact Details</b></h6>
                                            <table style={{ width: "100%" }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "150px" }}>Temporary Address</td>
                                                        <td style={{ width: "10px" }}>:</td>
                                                        <td>{Student.Temporary_Address}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Permanent Residence</td>
                                                        <td>:</td>
                                                        <td>{Student.Permanent_Address}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Contact Phone</td>
                                                        <td>:</td>
                                                        <td>{Student.Contact_Phone}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mobile</td>
                                                        <td>:</td>
                                                        <td>{Student.Contact_Mobile}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Email Address</td>
                                                        <td>:</td>
                                                        <td>{Student.Contact_Email}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Class Details</td>
                                                        <td>:</td>
                                                        <td>{Student?.SelectedClass?.Class_Name}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr style={{ border: "transparent" }}>
                                        <td colSpan={2}>
                                            <h6 className=""><b>Facilities Taking</b></h6>
                                            <table style={{ width: "100%" }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "150px" }}>Transport Facility</td>
                                                        <td style={{ width: "10px" }}>:</td>
                                                        <td>{Student?.SelectedRoot?.Root_Name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Concession Rule</td>
                                                        <td>:</td>
                                                        <td>{Student?.SelectedConcessionRule?.Concession_Rule_Name}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr style={{ border: "transparent" }}>
                                        <td colSpan={2}>
                                            <h6 className=""><b>Other Information</b></h6>
                                            <table style={{ width: "100%" }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "150px" }}>Prev. School Info</td>
                                                        <td style={{ width: "10px" }}>:</td>
                                                        <td>{Student.Previous_School_Info}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>NOC Board</td>
                                                        <td>:</td>
                                                        <td>{Student.NOC_Board}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Remarks</td>
                                                        <td>:</td>
                                                        <td>{Student.Remarks}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr style={{ border: "transparent" }}>
                                        <td colSpan={2}>
                                            <h6 className=""><b>Ledger Detail</b></h6>
                                            <table className="table table-sm table-bordered table-print white-space-wrap">
                                                <thead>

                                                    <tr>
                                                        <th>Transaction ID</th>
                                                        <th>DATED</th>
                                                        <th>FEE CODE</th>
                                                        <th>RECEIPT NO.</th>
                                                        <th>DESCRIPTION</th>
                                                        <th>DEBIT</th>
                                                        <th>CREDIT</th>
                                                        <th>Balance</th>
                                                        <th>Balance Type</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {loading ? (
                                                        <tr>
                                                            <td className="text-center" colSpan="10">
                                                                <Spinner animation="border" role="status"></Spinner>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <>{renderLedger()}
                                                            {listOfLedger != null && listOfLedger.length > 0 &&
                                                                <tr>
                                                                    <th colSpan={5} className="text-end">Total :</th>
                                                                    <th>{totalDebit}</th>
                                                                    <th>{totalCredit}</th>
                                                                    <td colSpan="3"></td>
                                                                </tr>
                                                                /* <tr>
                                                                  <th colSpan={6} className="text-end">Total Payable :</th>
                                                                  <th>{totalDebit - totalCredit}</th>
                                                                  <td colSpan="4"></td>
                                                                </tr> */
                                                            }
                                                        </>
                                                    )}

                                                    {/* <tr>
                      <td colSpan={10}>
                        <div className="row">
                          <div className="col-md-2 offset-md-10">
                            <button
                              type="button"
                              className="button button-primary w-100"
                              onClick={(e) => handleShowForAttchDues(e)}
                            >
                              Attach Dues
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr> */}
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                    <tr style={{ border: "transparent" }}>
                                        <td colSpan={2}>
                                            <h6 className=""><b>Attendence</b></h6>
                                            <table className="table table-sm table-bordered table-print white-space-wrap">
                                                <thead>

                                                    <tr>
                                                        <th>Student ID</th>
                                                        <th>Student Name</th>
                                                        <th>Class Name</th>
                                                        <th>Registration NO</th>
                                                        <th>Absent Level</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {loading ? (
                                                        <tr>
                                                            <td className="text-center" colSpan="10">
                                                                <Spinner animation="border" role="status"></Spinner>
                                                            </td>
                                                        </tr>
                                                    ) : (

                                                        <>
                                                            {
                                                                AttendenceList.map((item, index) => (
                                                                    <tr>
                                                                        <td>{item.Student_ID}</td>
                                                                        <td>{item.Student_Name}</td>
                                                                        <td>{item.Class_Name}</td>
                                                                        <td>{item.Registration_No}</td>
                                                                        <td>{item.Absent_Leave}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                    <tr style={{ border: "transparent" }}>
                                        <td colSpan={2}>
                                            <h6 className=""><b>History</b></h6>
                                            <table className="table table-sm table-bordered table-print white-space-wrap">
                                                <thead>

                                                    <tr>
                                                        <th>Serial No</th>
                                                        <th>Student Name</th>
                                                        <th>Status Detail</th>
                                                        <th>Dated</th>
                                                        <th>Modified By</th>
                                                        <th>REMARKS</th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {loading ? (
                                                        <tr>
                                                            <td className="text-center" colSpan="10">
                                                                <Spinner animation="border" role="status"></Spinner>
                                                            </td>
                                                        </tr>
                                                    ) : (

                                                        <>
                                                            {
                                                                History.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{item.Student_Name}</td>
                                                                        <td></td>
                                                                        <td>{moment(item.Dated).format("DD/MM/YYYY")}</td>
                                                                        <td>{item.Modified_By}</td>
                                                                        <td>{item.Remarks}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </>

                }


            </Page >
        </>
    );
}

export default PrintStudentProfile;


