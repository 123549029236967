import React, { useState, useEffect } from "react";
import StudentMenu from "./StudentMenu";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import SessionsDropDown from "../Shared/SessionsDropDown";
import SectionDropDown from "../Shared/SectionDropDown";
import BusrouteDropDown from "../Shared/BusrouteDropDown";
import ConcessionDropDown from "../Shared/ConcessionDropDown";
import StudentGroupDropDown from "../Shared/StudentGroupDropDown";
import { toast, ToastContainer } from "react-toastify";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { NavLink, useNavigate } from "react-router-dom";
import { scrollToTop } from "../Shared/ScrollOnTop";
import moment from "moment";

function AddStudent(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [SessionID, setSessioinId] = useState(
    localStorage.getItem("DefaultSession")
  );
  const [WingID, setWingId] = useState(0);
  const [ClassID, setClassId] = useState(0);
  const [TransportId, setTransportId] = useState(-1);
  const [SectionID, setSectionID] = useState(0);
  const [ConcessionID, setConcessionId] = useState(1);
  const [GroupId, setGroupId] = useState(1);
  const [loading, setLoading] = useState(false);

  const [BayForm, setBayForm] = useState("");
  const [FormNo, setFormNo] = useState("");
  const [StudentName, setStudentName] = useState("");
  const [DateOfBirth, setDateOfBirth] = useState("");
  const [FatherName, setFatherName] = useState("");
  const [FatherCNIC, setFatherCNIC] = useState("");
  const [FamilyNo, setFamilyNo] = useState("");
  const [MotherName, setMotherName] = useState("");
  const [Tehsil, setTehsil] = useState("");
  const [District, setDistrict] = useState("");

  const [VaccinationDate, setVaccinationDate] = useState("");
  const [VaccinationStatus, setVaccinationStatus] = useState("");

  const [TemporaryAddress, setTemporaryAddress] = useState("");
  const [PermanentAddress, setPermanentAddress] = useState("");
  const [ContactNumber, setContactNumber] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");
  const [EmailAddress, setEmailAddress] = useState("");
  const [PreviousSchoolInfo, setPreviousSchoolInfo] = useState("");
  const [NOCBoard, setNOCBoard] = useState("");
  const [Remarks, setRemarks] = useState("");
  const [RegNo, setRegNo] = useState("");
  const [RegImmediate, setRegImmediate] = useState(false);
  const [Gender, setGender] = useState(true);

  // photo
  const [isUpdated, setIsUpdated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [cropData, setCropData] = useState("");
  const [cropper, setCropper] = useState();
  const [imageSelected, setImageSelected] = useState(false);
  const [imageCropped, setImageCropped] = useState(false);
  const [imgData, setImageData] = useState("");

  const navigate = useNavigate();

  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => {
    setShowModal(false);
    setImageSelected(false);
    setLoading(false);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.checked);
  };

  const [validated, setValidated] = useState(false);

  useEffect(() => {
    document.title = "Add New Student";
    //console.log(WingID);
    //console.log(SessionID);
  }, [WingID, SessionID, ClassID]);

  const handleSubmit = (event) => {
    //console.log("RegImmediate", RegImmediate);
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (FatherCNIC.length < 13) {
      toast.error("Father CNIC must be 13 digits long.")
    } else {
      setLoading(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        Registration_No: RegNo,
        Student_Name: StudentName,
        Father_Name: FatherName,
        Father_CNIC: FatherCNIC,
        Temporary_Address: TemporaryAddress,
        Permanent_Address: PermanentAddress,
        Date_Of_Birth: DateOfBirth,
        Contact_Phone: ContactNumber,
        Contact_Mobile: MobileNumber,
        Contact_Email: EmailAddress,
        Bay_Form_No: BayForm,
        Class_ID: ClassID,
        Previous_School_Info: PreviousSchoolInfo,
        NOC_Board: NOCBoard,
        Remarks: Remarks,
        Form_No: FormNo,
        Concession_Rule_ID: ConcessionID,
        Current_Route_Id: TransportId,
        Student_Group_Id: GroupId,
        Section_Id: SectionID,
        Vaccination_Status: VaccinationStatus,
        Vaccination_Date: VaccinationDate,
        IsRegisterImmediately: RegImmediate,
        Gender: Gender,
        Image: cropData,
        FamilyNo: FamilyNo,
        MotherName: MotherName,
        Tehsil: Tehsil,
        District: District,
      };

      ////console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Student/AddNewStudent",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            navigate(
              `${RegImmediate
                ? "/students/SearchStudents"
                : "/students/NewStudentList"
              }`
            );
          } else {
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Network Error..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    setValidated(true);
  };

  const onChange = (e) => {
    e.preventDefault();
    setImageSelected(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      var cropData1 = cropper.getCroppedCanvas().toDataURL();
      setImageCropped(true);

      var cleanerBase64 = cropData1.substring(22);
      setCropData(cleanerBase64);
      setImageData(cropData1);
    }

    handleCloseModal();
  };

  const renderButtons = () => {
    if (imageSelected === true) {
      return (
        <>
          <div className="modal-footer justify-content-center">
            <button
              className="button button-outline-primary me-2"
              onClick={handleCloseModal}
            >
              Close
            </button>
            <button className="button button-primary" onClick={getCropData}>
              Save Photo
            </button>
          </div>
        </>
      );
    } else if (imageSelected === false && isUpdated === true) {
      return (
        <>
          <div className="modal-footer justify-content-center">
            <button
              className="button button-outline-primary me-2"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <StudentMenu />
      <ToastContainer />
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Profile Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isUpdated ? (
            <>
              <div className="text-center">
                <p>Photo Updated Successfully</p>
              </div>
            </>
          ) : (
            <>
              <div className="upload-photo-wrap">
                {imageSelected ? (
                  <Cropper
                    zoomTo={0.5}
                    aspectRatio={8 / 8}
                    preview=".img-preview"
                    src={image}
                    dragMode="move"
                    viewMode={2}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    onInitialized={(instance) => {
                      setCropper(instance);
                    }}
                    guides={true}
                  />
                ) : (
                  <div className="choose-photo">
                    <label htmlFor="ChoosePhoto">
                      <i className="ri-upload-cloud-line"></i>
                      <span>Browse Photo</span>
                      <input
                        id="ChoosePhoto"
                        accept="image/jpeg,image/png"
                        type="file"
                        onChange={onChange}
                      />
                    </label>
                  </div>
                )}
              </div>
            </>
          )}
        </Modal.Body>

        {renderButtons()}
      </Modal>
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/students/StudentDashboard">
                        Students
                      </NavLink>
                    </li>

                    <li className="breadcrumb-item active" aria-current="page">
                      Add Student
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Add Student</div>
              </div>
              <div className="col-md-6">
                <div className="page-right-actions">
                  <NavLink
                    to="/students/ImportStudent"
                    className="button button-white button-icon"
                  >
                    Import Students
                    <i className="ri-add-line"></i>
                  </NavLink>
                  <NavLink
                    to="/students/SearchStudents"
                    className="button button-white button-icon"
                  >
                    Search Students
                    <i className="ri-search-line"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body card-form-wrap">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <div className="form-wrap">
                        <div className="form-legend">Basic Information</div>
                        <div className="row align-items-center">
                          <div className="col-md-9">
                            <div className="row align-items-center g-3 m-0">
                              <label

                                className="col-md-2 col-sm-4 col-form-label col-form-label-sm"
                              >
                                Bay Form .
                              </label>
                              <div className="col-md-4 col-sm-8">
                                <input
                                  type="number"
                                  numbers-Only
                                  required
                                  className="form-control"
                                  value={BayForm}
                                  onChange={(e) => setBayForm(e.target.value)}
                                  id=""
                                />
                              </div>
                              <label

                                className="col-md-2 col-sm-4 col-form-label col-form-label-sm"
                              >
                                Form No.
                              </label>
                              <div className="col-md-4 col-sm-8">
                                <input
                                  type="number"
                                  numbers-Only
                                  className="form-control"
                                  value={FormNo}
                                  onChange={(e) => setFormNo(e.target.value)}
                                />
                              </div>
                              <label

                                className="col-md-2 col-sm-4 col-form-label col-form-label-sm"
                              >
                                Student Name
                              </label>
                              <div className="col-md-4 col-sm-8">
                                <input
                                  type="text"
                                  required
                                  className="form-control"
                                  value={StudentName}
                                  onChange={(e) => {
                                    if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                      setStudentName(e.target.value);
                                    }
                                  }}
                                  id=""
                                />

                              </div>
                              <label

                                className="col-md-2 col-sm-4 col-form-label col-form-label-sm"
                              >
                                Date of Birth
                              </label>
                              <div className="col-md-4 col-sm-8">
                                <input
                                  type="date"
                                  required
                                  className="form-control"
                                  value={DateOfBirth}
                                  onChange={(e) =>
                                    setDateOfBirth(e.target.value)
                                  }
                                  max={moment()
                                    .subtract(3, "years")
                                    .format(config.date_format_input)}
                                />
                              </div>
                              <label

                                className="col-md-2 col-sm-4 col-form-label col-form-label-sm"
                              >
                                {" "}
                                Father Name{" "}
                              </label>
                              <div className="col-md-4 col-sm-8">

                                <input
                                  type="text"
                                  required
                                  className="form-control"
                                  value={FatherName}
                                  onChange={(e) => {
                                    if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                      setFatherName(e.target.value);
                                    }
                                  }}

                                  id=""
                                />
                              </div>
                              <label

                                className="col-md-2 col-sm-4 col-form-label col-form-label-sm"
                              >
                                Father CNIC
                              </label>
                              <div className="col-md-4 col-sm-8">
                                <input
                                  type="number"
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (inputValue.length <= 13) {
                                      setFatherCNIC(inputValue);
                                    }
                                  }}
                                  minLength={13}
                                  maxLength={13}
                                  className="form-control"
                                  value={FatherCNIC}
                                />
                              </div>

                              <label

                                className="col-md-2 col-sm-4 col-form-label col-form-label-sm"
                              >
                                Family No
                              </label>
                              <div className="col-md-4 col-sm-8">
                                <input
                                  type="number"
                                  className="form-control"
                                  value={FamilyNo}
                                  onChange={(e) => setFamilyNo(e.target.value)}
                                />
                              </div>
                              <label

                                className="col-md-2 col-sm-4 col-form-label col-form-label-sm"
                              >
                                Mother Name
                              </label>
                              <div className="col-md-4 col-sm-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={MotherName}
                                  onChange={(e) => {
                                    if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                      setMotherName(e.target.value);
                                    }
                                  }}
                                />
                              </div>

                              <label

                                className="col-md-2 col-sm-4 col-form-label col-form-label-sm"
                              >
                                Gender
                              </label>
                              <div className="col-md-9">
                                <div className="mt-1">
                                  <div className="form-check form-check-inline">
                                    <label className="form-check-label">
                                      <input
                                        type="radio"
                                        name="gender"
                                        value="male"
                                        checked={Gender === true}
                                        onChange={() => setGender(true)}
                                      />
                                      Male
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline me-0">
                                    <label className="form-check-label">
                                      <input
                                        type="radio"
                                        name="gender"
                                        value="female"
                                        checked={Gender === false}
                                        onChange={() => setGender(false)}
                                      />
                                      Female
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 text-center">
                            <img
                              className="mb-3"
                              src={imgData}
                              style={{ width: "150px" }}
                            />

                            <button
                              type="button"
                              onClick={handleShowModal}
                              className="button  button-small"
                            >
                              Choose Photo
                            </button>
                          </div>
                        </div>
                        <div className="row gy-3 align-items-center mt-0">
                          <label

                            className="col-md-2 col-sm-6 col-form-label col-form-label-sm"
                          >
                            Vaccination Status
                          </label>
                          <div className="col-md-4 col-sm-6">
                            <select
                              className="form-select"
                              required
                              onChange={(e) =>
                                setVaccinationStatus(e.target.value)
                              }
                            >
                              {/* <option value="" disabled >Please Select</option> */}
                              <option value="Not Vaccinated" selected>
                                Not Vaccinated
                              </option>
                              <option value="Partially Vaccinated">
                                Partially Vaccinated
                              </option>
                              <option value="Fully Vaccinated">
                                Fully Vaccinated
                              </option>
                            </select>
                          </div>
                          <label

                            className="col-md-2 col-sm-6 col-form-label col-form-label-sm"
                          >
                            Vaccination Date
                          </label>
                          <div className="col-md-4 col-sm-6">
                            <input
                              type="date"
                              className="form-control"
                              value={VaccinationDate}
                              onChange={(e) =>
                                setVaccinationDate(e.target.value)
                              }
                              id=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-wrap">
                        <div className="form-legend">Contact Information</div>
                        <div className="row g-3">
                          <label

                            className="col-md-3 col-sm-12 col-form-label col-form-label-sm"
                          >
                            Temporary Address
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              className="form-control"
                              value={TemporaryAddress}
                              onChange={(e) =>
                                setTemporaryAddress(e.target.value)
                              }
                              id=""
                            />
                          </div>

                          <label

                            className="col-md-3 col-sm col-form-label col-form-label-sm"
                          >
                            Permanent Address
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              className="form-control"
                              value={PermanentAddress}
                              onChange={(e) =>
                                setPermanentAddress(e.target.value)
                              }
                              id=""
                            />
                          </div>

                          <label

                            className="col-md-3 col-sm-6 col-form-label col-form-label-sm"
                          >
                            Tehsil
                          </label>
                          <div className="col-md-3 col-sm-6">
                            <input
                              type="text"
                              className="form-control"
                              value={Tehsil}
                              onChange={(e) => setTehsil(e.target.value)}
                              id=""
                            />
                          </div>
                          <label

                            className="col-md-3 col-sm-6 col-form-label col-form-label-sm"
                          >
                            District
                          </label>
                          <div className="col-md-3 col-sm-6">
                            <input
                              type="text"
                              className="form-control"
                              value={District}
                              onChange={(e) => setDistrict(e.target.value)}
                              id=""
                            />
                          </div>

                          <label

                            className="col-md-3 col-sm-6 col-form-label col-form-label-sm"
                          >
                            Contact Number
                          </label>
                          <div className="col-md-3 col-sm-6">
                            <input
                              type="number"
                              numbers-Only
                              className="form-control"
                              value={ContactNumber}
                              onChange={(e) => setContactNumber(e.target.value)}
                            />
                          </div>
                          <label

                            className="col-md-3 col-sm-6 col-form-label col-form-label-sm"
                          >
                            Mobile Number
                          </label>
                          <div className="col-md-3 col-sm-6">
                            <input
                              type="number"
                              numbers-Only
                              className="form-control"
                              value={MobileNumber}
                              onChange={(e) => setMobileNumber(e.target.value)}
                            />
                          </div>

                          <label

                            className="col-md-3 col-sm-6 col-form-label col-form-label-sm"
                          >
                            Email Address
                          </label>
                          <div className="col-md-9">
                            <input
                              type="email"
                              className="form-control"
                              value={EmailAddress}
                              onChange={(e) => setEmailAddress(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-wrap">
                        <div className="form-legend">
                          Session / Wing / Class / Section
                        </div>
                        <div className="row gy-3">
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Choose Session</label>
                              <select
                                className="form-select"
                                required
                                value={SessionID}
                                onChange={(e) => {
                                  setSessioinId(e.target.value);
                                  setSectionID(0);
                                  setClassId(0);
                                }}
                                size={4}
                                style={{ height: "200px" }}
                              >
                                <option value="">Please Select</option>
                                <SessionsDropDown selectedSession={SessionID} />
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Choose Wing</label>
                              <select
                                onChange={(e) => {
                                  setWingId(e.target.value);
                                  setSectionID(0);
                                  setClassId(0);
                                }}
                                className="form-select"
                                required
                                size={4}
                                style={{ height: "200px" }}
                              >
                                <option value="">Please Select</option>
                                <WingsDropDown />
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Select Class:</label>
                              <select
                                className="form-select"
                                required
                                onChange={(e) => setClassId(e.target.value)}
                                size={4}
                                style={{ height: "200px" }}
                              >
                                <option value="">Please Select</option>
                                {SessionID != 0 && WingID != 0 && (
                                  <ClassesDropDown
                                    SessionID={SessionID}
                                    WingID={WingID}
                                  />
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Select Section</label>
                              <select
                                className="form-select"
                                required
                                onChange={(e) => setSectionID(e.target.value)}
                                size={4}
                                style={{ height: "200px" }}
                              >
                                <option value="">Please Select</option>
                                {ClassID != 0 && (
                                  <SectionDropDown ClassID={ClassID} />
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-wrap">
                        <div className="form-legend">
                          Transport / Concessions / Group
                        </div>
                        <div className="row gy-3">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label >Transport Facility</label>
                              <select
                                className="form-select"
                                onChange={(e) => setTransportId(e.target.value)}
                              >
                                <option value="-1">Please Select</option>
                                {<BusrouteDropDown />}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label >Select Concessions</label>
                              <select
                                className="form-select"
                                value={ConcessionID}
                                required
                                onChange={(e) =>
                                  setConcessionId(e.target.value)
                                }
                              >
                                <option value="">Please Select</option>
                                {<ConcessionDropDown />}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Select Group</label>
                              <select
                                className="form-select"
                                value={GroupId}
                                required
                                onChange={(e) => setGroupId(e.target.value)}
                              >
                                <option value="">Please Select</option>
                                {<StudentGroupDropDown />}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-wrap">
                        <div className="form-legend">Other Details</div>
                        <div className="row align-items-center gy-3">
                          <label

                            className="col-md-3 col-form-label col-form-label-sm"
                          >
                            Previous School Information
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              className="form-control"
                              value={PreviousSchoolInfo}
                              onChange={(e) =>
                                setPreviousSchoolInfo(e.target.value)
                              }
                            />
                          </div>

                          <label

                            className="col-md-3 col-form-label col-form-label-sm"
                          >
                            NOC Board Information
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              className="form-control"
                              value={NOCBoard}
                              onChange={(e) => setNOCBoard(e.target.value)}
                            />
                          </div>

                          <label

                            className="col-md-3 col-form-label col-form-label-sm"
                          >
                            Remarks
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              className="form-control"
                              value={Remarks}
                              onChange={(e) => setRemarks(e.target.value)}
                            />
                          </div>
                          <label

                            className="col-md-3 col-form-label col-form-label-sm"
                          >
                            Registration Number
                          </label>
                          <div className="col-md-4 col-sm-8">
                            <input
                              type="number"
                              className="form-control"
                              value={RegNo}
                              onChange={(e) => setRegNo(e.target.value)}
                            />
                          </div>
                          <div className="col-md-8 offset-md-3 text-end">
                            <div className="form-text">
                              Note: If you will not write registration No. an
                              automatic registration No. will be assigned.
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 offset-md-3">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck1"
                                value={RegImmediate}
                                onChange={(e) => setRegImmediate(!RegImmediate)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleCheck1"
                              >
                                Register Immediately
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 offset-md-9 text-md-end">
                            <div className="form-group ">
                              {loading ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                ></Spinner>
                              ) : (
                                <button
                                  type="submit"
                                  value="Save"
                                  className="mt-2 button button-primary w-100 "
                                >
                                  SAVE STUDENT
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddStudent;
